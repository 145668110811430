.description, .feature {
    background: rgba(208, 210, 215, 0.2);
    padding: 30px 20px;
    border: none;
    min-height: 500px;
}


dt.name {
    font-family: UDERTT;
}

dl.data-sheet {
    background-color: white;
}

.product-flag_serie {
    display: table;
    background-color: #ffd800;
    padding: 0px 20px;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    margin-top: 20px;
}
body {
  background-color: $wrapper-bg;
}
body#authentication,
body#my-account,
body#identity,
body#address,
body#addresses,
body#module-psgdpr-gdpr,
body#history,
body#cart,
body#order-slip,
body#module-blockwishlist-lists,
body#checkout,
body#pagenotfound,
body#order-confirmation {
  background-color: #e9e9ea;
    h1 {
      text-align: center;
      padding: 2rem 0;
    }
    .l-header {
      background-color: #e9e9ea;
    }
    .page-wrapper {
      border-radius: 5px;
      box-shadow: none;
      margin: 40px auto;
      background-color: #efefef;
      padding: 50px 0;
    }
    .page-header, .page-footer {
      background-color: transparent !important;
      border-bottom: none;
      border-top: none;
      text-align: center;
      padding-bottom: 1rem;
    }
    .form-footer {
      margin-top: 1rem;
      text-align: center;
    }
    .l-footer {
      background-color: #e9e9ea;
    }
    .block_newsletter{
      background-color: white !important;
    }
}

a {
  text-decoration: none;
}

sup {
  top: -0.3em;
}

@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}

.l-wrapper {
  @if $enable-fluid-layout {
    @extend .container-fluid;
  }

  @else {
    @extend .container;
  }

  &--boxed {
    @extend .container;

  }

}

.page-wrapper,
.card-block {
  @extend .card;

}

.page-header {
  @extend .card-header;

  h1 {
    margin-bottom: 0;
  }
}

.page-content,
.card-block {
  @extend .card-body;
}

.card-block {
  box-shadow: $card-box-shadow;
  ;
}

.page-footer {
  @extend .card-footer;

  &:empty {
    display: none;
  }

}

.left-column>div,
.right-column>div {
  margin-bottom: $spacer;
}

/* HEADER */

/* FOOTER */
.l-footer {
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}

.quick-view {
  display: inline-block;
  @extend .small;
}

.form-group.form-group_forgot-password {
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin {
  box-shadow: $bs-touchspin-boxshadow;
  border: $input-border-width solid $input-border-color;

  >input {
    max-width: 60px;
    border: none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .btn {
    position: relative;
    z-index: 2;
    color: $input-color;
    background-color: $white;

  }

  .input-group-btn {
    display: flex;

    &:first-child {
      margin-right: -$input-border-width;
    }

    &:last-child {
      margin-left: -$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background:white;
  transition: background 150ms;

  &:after {
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }

  &--lazyload:after {
    display: none;
  }
}

.rc>* {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rc>a {
  display: block;
}

.ratio2_1 {
  padding-top: 50%;
}

.ratio1_2 {
  padding-top: 200%;
}

.ratio4_3 {
  padding-top: 75%;
}

.ratio16_9 {
  padding-top: percentage(9/16);
}

.ratio1_1 {
  padding-top: 100%;
}

.ratio3_2 {
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse {
  transition: transform 0.4s ease;


}

.icon-collapse[aria-expanded=true] {
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body {
  padding-bottom: 0;

}

.step-edit[aria-expanded="true"] {
  display: none;
}

.step-title {
  margin-bottom: 0;
}

.-complete.-reachable .step-title .done {
  display: inline-block;
}

.-current .step-title .done {
  visibility: hidden;
}

.-current .card-header {
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }

  .card-body .card-header {
    text-align: center;
  }

  .selected .card {
    border-color: $success;
    border-width: 2px;
  }
}

//Personnal info
.nav-tabs-info {
  font-size: $font-size-base*1.1;

  .active {
    font-weight: bold;
  }
}

//Addresses

.address-selector {
  .card {
    transition: border 0.1s linear;

  }

  .card-header,
  .card-body {
    cursor: pointer;

  }
}

.card-body_add-address {
  padding: $card-spacer-y $card-spacer-x;

  .material-icons {
    opacity: .8;
  }
}

// SHipping
.delivery-option {
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}

.carrier-extra-content:not(:empty) {
  margin-bottom: $spacer*2;

  &:empty {
    display: none;
  }
}

.carrier-name {
  font-weight: $font-weight-bold;
}

.carrier-price {
  font-weight: bold;
  font-size: $font-size-lg;
  color: $primary;
}

//order list final recap
.order-line:not(:last-child) {
  margin-bottom: $spacer/2;
}

.order-confirmation-table {
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after {
  content: "" !important;

}

.label {
  @extend label;
}

//nav tabs
.nav-tabs {
  .nav-link {
    color: $gray-600;
    font-weight: 700;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}

.tab-content {
  padding-top: $spacer;
}

//products-section-title
.products-section-title {
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color: $section-title-color;
  font-size: $section-title-size;
  font-weight: $section-title-font-weight;
}

//home content spacing
.page-home>* {
  margin-bottom: $spacer-y;
}
section.product-accessories.mt-3 {
  width: 100%;
  padding: 15px !important;
}
@include media-breakpoint-mobile() {
  .favoris_btn {
    left: 75% !important;
  }

  .blockcart__count {
    position: absolute;
    top: 0;
    right: 15px;
  }
  .product-flag_serie {
    margin: 0 auto !important;
  }

  .block-category {
    width: 90% !important;
  }
  .product-miniature {
    .card-body {
      padding: 0.5rem !important;
    }
    .btn.btn-primary.add-to-cart {
      font-size: 12px;
    }
    .product-flag {
      font-size: 12px;
    }
  }
  button#search_filter_toggler img {
    margin-right: 10px;
  }

  button#search_filter_toggler {
    background-color: #1D1D1B;
    color:white;
    padding: 10px 40px;
    border-radius: 30px;
  }
  .row.category_mobile {
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .block_newsletter {
    padding: 40px !important;
  }
  .newsletter form.needs-validation input {
    width: 85% !important;
    float: left;
    display: inherit;
  }
  .modal-footer {
    flex-direction: column;
    flex-direction: column-reverse;

    &>.btn {
      margin: 0 auto;
      width: 90%
    }

    & .btn:not(:last-child) {
      margin-top: $spacer/2;
    }

  }

  .col-lg-6.product-information {
    text-align: center;
  }

  .add-to-cart.btn-lg {
    width: 100%;
  }

  input.form-control {
    width: 100% !important;
    margin-bottom: 10px;
    border: none;
    box-shadow: none;
  }

  .js-mailalert-add {
    width: 100%;
  }

  .blockreassurance .block-title {
    display: block !important;
    font-size: 16px !important;
    display: block !important;
    text-align: center !important;
    max-width: 100% !important;
    height: auto !important;
  }

  .blockreassurance .block-icon img,
  .blockreassurance .block-icon svg {
    width: 70px !important;
    height: 70px !important;
  }

  .blockreassurance .block-icon {
    height: 70px !important;
    width: 100% !important;
    display: block !important;
    float: initial !important;
  }

  .footer_description {
    display: block !important;
    width: 100% !important;
    text-align: center;
  }

  .block-contact {
    text-align: center;
    padding-bottom: 40px;
  }

  .footer-container {
    text-align: center;
  }
  .product-flag {
    font-size: 11px;
  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }

  .nav-link {
    white-space: nowrap;
  }
}

//forms
main>.notifications-container {
  @extend .l-wrapper;
}

.notifications-container {
  ul {
    margin-bottom: 0;
  }
}

.js-invalid-feedback-browser:empty {
  display: none;
}

.form-group {

  label,
  .label {

    small,
    .small {
      font-size: .75 * $font-size-base;
    }
  }
}

@include media-breakpoint-desktop {

  .js-checkout-step-header {
    display: flex;
    .step-title.h3 {
      font-size: 1.5rem;
    }
  }
  .page-content,
  .checkout-form,
  .page-wrapper--order-confirmation {
    form {
      width: 75%;
      max-width: 450px;
      margin: 0 auto;
    }
  }

  header#header {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  div#_desktop_contact_link a {
    color: white;
  }

  .page-wrapper--order-detail {
    form {
      margin: unset;
    }
  }
}

.form-footer {
  margin-top: $spacer;
}

// badge order history
.badge.bright {
  color: #fff;
}

.block-category {
  width: 70%;
  margin: 0 auto;
  border: none;
}

div#left-column {
  position: sticky;
  top: 20px;
  height: 100%;
}

.breadcrumb {
  background-color: transparent;
  font-size: 12px;
}

.block_newsletter {
  background: rgba(208,210,215,0.2);
  padding: 75px;
}

p#block-newsletter-label {
  font-size: 32px;
  font-family: "UDERTT";
  color: black;
}

.block_newsletter {
  p {
    font-size: 16px;
    color: black;
  }
  .btn-primary {
    background-color: white !important;
    color: black !important;
    border-radius: 5px;
    height: 50px;
  }
  .btn-primary:hover {
    background-color: black !important;
    color: white !important;
  }
  input.form-control {
    height: 50px;
    border: 1px solid;
    margin-right: 1px;
  }
}
.breadcrumb-item.active span {
  color: black;
  text-decoration: underline;
}

.blockreassurance {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.blockreassurance .block-title {
  padding-top: 15px !important;
  font-weight: normal !important;
}

.page-link {
  border: none;
}

.page-item.active .page-link {
  border-radius: 50%;
  background-color: #FFDB00;
  color: black;
}

div#custom-text {
  border: none;
  text-align: center;
}

p.data-sheet {
  background-color: white;
  padding: 10px;
  margin-bottom: 0.7rem;
}

span.value {
  float: right;
}

span.name {
  font-family: 'UDERTT';
  font-size: 18px;
}

.product-manufacturer {
  text-transform: uppercase;
}

.js-mailalert input {
  width: 40%;
  float: left;
  display: -webkit-inline-box;
  margin-right: 10px;
}

.js-mailalert-add {
  color: black;
  background: white;
  border: 1px solid #1D1D1B;
  border-radius: 5px;
}

.favoris_btn {
  position: absolute;
  left: 85%;
  top: 10px;
  z-index: 1;
  .tabs {
    display: none;
  }
  .pw-fav-btn-text {
    display: none;
  }
  img {
    width: 60px;
    height: 60px;
  }
}

.product-additional-info .pw-fav-toggle {
  display: none;
}

.product-additional-info a.pw-fav-toggle {
  display: none;
}

.product-flag {
  margin: 1px;
}

.card.card-product .btn.btn-primary.add-to-cart {
  width: 100% !important;
  border-radius: 5px;
}

.btn-primary:disabled {
  background-color: #e4e4e8;
  color: #666;
  border: none;
}

div#mobile_top_menu_wrapper {
  height: 100vh;
  .modal-footer {
    background: rgba(208, 210, 215, 0.2);
  }
  .close {
    font-size: 2rem !important;
  }
  .modal-footer{
    align-items: start;
  }
}


.btn.focus, .btn:focus {
  box-shadow: none !important;
}


#header button.btn.btn-sm.dropdown-toggle {
  color: #fff!important;
  padding: 0px;
  font-size: 16px;
  margin: 0px;
  text-transform: uppercase;
}

#header .dropdown-menu.show {
  left: -75px;
}

.card.card-product .product-flags {
  min-height: 70px;
}

#btn-back-to-top {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  display: none;
  background-color: whitesmoke;
  border-radius: 50%;
  padding: 10px;
}

.logo_paiement img {
  width: 40px;
  margin-left: 20px;
}

.newsletter-subtext {
  color:  #000;
  text-align: left;
  margin-top: 30px;
  font-family: Roboto, Arial;
  font-size: 1rem;

  a {
    font-weight: 600;
    text-decoration: underline;
  }
}

#onetrust-banner-sdk .ot-sdk-column, #onetrust-banner-sdk .ot-sdk-columns, #onetrust-pc-sdk .ot-sdk-column, #onetrust-pc-sdk .ot-sdk-columns, #ot-sdk-cookie-policy .ot-sdk-column, #ot-sdk-cookie-policy .ot-sdk-columns {
  margin-left: 0 !important;
}

#ot-sdk-btn.ot-sdk-show-settings.custom-page-link, #ot-sdk-btn.optanon-show-settings {
  color: #000;
  border: none;
  padding: 0;
  font-size: 1rem;

  &:hover {
    color: initial;
    background-color: initial;
  }
}

.phone_mobile-container {
  display: flex;
  align-items: center;

  span {
    background: #fafafa;
    width: auto;
  }
}

@media (max-width: 991px) {
  .phone_mobile-container {
    span {
      margin-bottom: 10px;
    }
  }
}

/* PRODUCT MINIATURE */
$pm-bg-color: rgba(208, 210, 215, 0.2);
$pm-border-width: null;
$pm-border-color: null;
/* product title */
$pm-name-color:                $primary;
$pm-name-hover-color:          $primary;
$pm-name-font-size:            20px;
$pm-name-font-weight:          400;
$pm-name-font-family: null;
$pm-name-font-size: null;
$pm-name-hover-border-width: 1px;
$pm-name-hover-border-color: transparent;

$pm-highlight-bg-color: #fff;
$pm-highlight-translateY: 0;
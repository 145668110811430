.search-widget{
  max-width: 330px;
}
.search-widget__group{
  position: relative;
}
.search-widget__input-right{
  padding-right: 50px ;
}
.search-widget__btn{
  position: absolute;
  right: 0;
  top: 0;
}
@include media-breakpoint-mobile{
  .search-widget{
    max-width: 92%;
  }
}

.search-widget__input-right {
  background: rgba(208, 210, 215, 0.2);
  border-radius: 5px;
  font-size: 13px;
}